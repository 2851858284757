export default {
  async insert (Vue, formData, idcomercial) {
    const respClientePotencial = await Vue.$api.call(
      'clientePotencial.insert',
      {
        values: {
          idcomercial,
          idempresa: formData.idempresa,
          idtcliente: formData.idtcliente,
          idpais: formData.idpais,
          idagente_comercial: formData.idagente_comercial,
          nombre: formData.nombre,
          razon_social: formData.razon_social,
          observaciones: formData.observaciones,
          idcanal_conocimiento: formData.idcanal_conocimiento,
          idtsector: formData.idtsector,
          idttarifa: formData.idttarifa,
          ididioma: formData.ididioma,
          cif: formData.cif,
          prospecto: formData.prospecto,
        },
      },
    )
    const idclientePotencial = respClientePotencial.data.result.dataset[0].idcliente_potencial
    const respClienteDireccion = await Vue.$api.call(
      'clienteDireccion.insert',
      {
        values: {
          idcliente_potencial: idclientePotencial,
          idtdireccion: formData.direccion_idtdireccion,
          calle: formData.direccion_calle,
          num: formData.direccion_num,
          cp: formData.direccion_cp,
          idpais: formData.direccion_idpais,
          idtvia: formData.direccion_idtvia,
          otros_datos: formData.direccion_otros_datos,
          idzona_comercial: formData.direccion_idzona,
          idzona_tecnico: formData.direccion_idzona,
          idzona_vigilancia: formData.direccion_idzona,
          idprovincia: formData.direccion_idprovincia,
          idlocalidad: formData.direccion_idlocalidad,
        },
      },
    )
    const idclienteDireccion = respClienteDireccion.data.result.dataset[0].idcliente_direccion
    let idclienteDireccionServicio = idclienteDireccion
    if (formData.direccion_servicio) {
      const respClienteDireccionServicio = await Vue.$api.call(
        'clienteDireccion.insert',
        {
          values: {
            idcliente_potencial: idclientePotencial,
            idtdireccion: formData.direccion_servicio_idtdireccion,
            calle: formData.direccion_servicio_calle,
            num: formData.direccion_servicio_num,
            cp: formData.direccion_servicio_cp,
            idpais: formData.direccion_servicio_idpais,
            idtvia: formData.direccion_servicio_idtvia,
            otros_datos: formData.direccion_servicio_otros_datos,
            idzona_comercial: formData.direccion_servicio_idzona,
            idzona_tecnico: formData.direccion_servicio_idzona,
            idzona_vigilancia: formData.direccion_servicio_idzona,
            idprovincia: formData.direccion_servicio_idprovincia,
            idlocalidad: formData.direccion_servicio_idlocalidad,
          },
        },
      )
      idclienteDireccionServicio = respClienteDireccionServicio.data.result.dataset[0].idcliente_direccion
    }
    await Vue.$api.call(
      'clientePotencial.update',
      {
        values: {
          idcliente_potencial: idclientePotencial,
          idclidir_postal: idclienteDireccion,
          idclidir_fiscal: idclienteDireccion,
          idclidir_sistema: idclienteDireccionServicio,
        },
      },
    )
    await Vue.$api.call(
      'clienteTelefono.insert',
      {
        values: {
          idcliente_potencial: idclientePotencial,
          idttelefono: formData.telefono_idttelefono,
          telefono: formData.telefono_telefono,
          horario: formData.telefono_horario,
          observaciones: formData.telefono_observaciones,
          email: formData.telefono_email,
          nombre: formData.telefono_nombre,
          email_notifica_admon: formData.telefono_email_notifica_admon,
          email_notifica_comercial: formData.telefono_email_notifica_comercial,
          email_notifica_sat: formData.telefono_email_notifica_sat,
          es_responsable_propiedad: true,
          para_todos_los_sistemas: formData.telefono_para_todos_los_sistemas,
        },
      },
    )
    if (formData.representante) {
      await Vue.$api.call(
        'representante.insert',
        {
          values: {
            idcliente_potencial: idclientePotencial,
            nombre: formData.representante_nombre,
            dni: formData.representante_dni,
            domicilio: formData.representante_domicilio,
            cargo: formData.representante_cargo,
          },
        },
      )
    }
    return respClientePotencial.data.result.dataset[0]
  },
}
