<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <cliente-potencial-add-form
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ClientePotencialAddForm from './components/ClientePotencialAddForm'
import Data from './ClientePotencialAddData'
import { get } from 'vuex-pathify'

export default {
  components: {
    ClientePotencialAddForm
  },
  mixins: [formPageMixin],
  computed: {
    usuarioIdcomercial: get('usuario/idcomercial'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir cliente potencial'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    formInvalid () {
      this.$alert.showSnackbarError('Alguno de los pasos del asistente contiene datos incorrectos')
    },
    async submitForm () {
      const inserted = await Data.insert(this, this.formData, this.usuarioIdcomercial)
      this.$appRouter.replace({
        name: 'clientespotenciales__cliente-potencial-view',
        params: {
          idcliente_potencial: inserted.idcliente_potencial,
        },
      })
    },
  },
}
</script>
